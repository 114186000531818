import React from "react"
import { Button, Col, Row } from "reactstrap"

const SubmitedList = ({ submited, setSubmited }) => {
  const RemoveItemHandler = itemKey => {
    setSubmited(prev => {
      const updateItems = prev.filter((ite, key) => key !== itemKey)
      return [...updateItems]
    })
  }

  return (
    <React.Fragment>
      <Row style={{ marginTop: 20, justifyContent: "center" }}>
        {submited.map((item, key) => (
          <Col
            key={key}
            lg={2}
            style={{
              boxShadow: "1px 2px 9px #e2e2e2",
              marginBottom: 10,
              padding: 5,
              margin: 10,
            }}
          >
            <Button
              className="btn btn-danger btn-sm"
              style={{ textAlign: "left !important" }}
              onClick={RemoveItemHandler.bind(this, key)}
            >
              x
            </Button>
            <div style={{ textAlign: "center" }}>
              <h4>{item.batch_number}</h4>
              <p style={{ fontSize: 15, fontWeight: 400 }}>
                {item.out_quantity}
              </p>
            </div>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default SubmitedList
