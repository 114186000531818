import React, { useEffect, useState } from "react"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import MonitorInventoryListComponent from "components/MonitorReports/Inventory"
import {
  getMonitorIps,
  getMonitorItemCategories,
  getMonitorItems,
  getMonitorStock,
} from "store/monitor-dashboard/monitor-dashboard-action"
import { exportMonitorInventoryReport } from "store/monitor-reports/monitor-report-action"

const MonitorInventoryList = props => {
  //meta title
  document.title = "Dashboard"

  const [params, setParams] = useState({})
  const monitorStock = useSelector(state => state.monitorDashboard.monitorStock)

  const dispatch = useDispatch()

  const onSubmit = values => {
    setParams(values)
    console.log(values)
    dispatch(getMonitorStock(values))
  }

  const onExport = () => {
    dispatch(exportMonitorInventoryReport(params))
  }

  const onAdvancedSearchHandler = filters => {
    // console.log(filters)
  }

  const onCategoryChange = category => {
    dispatch(getMonitorItems(category))
  }

  useEffect(() => {
    dispatch(getMonitorStock())
    dispatch(getMonitorIps())
    dispatch(getMonitorItemCategories())
  }, [])

  return (
    <React.Fragment>
      <Breadcrumbs title={"Monitor"} breadcrumbItem={"Inventory"} />
      <MonitorInventoryListComponent
        provStock={monitorStock?.data?.data?.monitorReports}
        onSubmit={onSubmit}
        onCategoryChange={onCategoryChange}
        onExport={onExport}
        onAdvancedSearchHandler={onAdvancedSearchHandler}
      />
    </React.Fragment>
  )
}

export default MonitorInventoryList
