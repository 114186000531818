import React from "react"
import { Card, CardBody } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"

const ItemListComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onClickDelete,
  exportStatus,
  onExportBtnClick,
}) => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            rightBtn={true}
            rightBtnTitle={"Add Item"}
            rightBtnRoute={"/add-item"}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "item_name", name: "Item Name" },
              { value: "unfp_item_name", name: "UNFPA Item Name" },
            ]}
          />
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={[
              "#",
              "Category Name",
              "UNFPA Item ID",
              "IP-Item description",
              "UNFPA Unit of measure (UOM)",
              "IP Unit UOM",
              "Action",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(item, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{item.itemCategory.name}</td>
                <td>{item.unfp_item_name}</td>
                <td>{item.ip_item_description}</td>
                <td>
                  {item.uom}{" "}
                  {item.unfpa_uom_quantity > 1 && item.unfpa_uom_quantity}
                </td>
                <td>
                  {item.ip_unit_umo}{" "}
                  {item.ip_uom_quantity > 1 && item.ip_uom_quantity}
                </td>
                <td>
                  <ActionColumns
                    data={item}
                    enableEdit={true}
                    enableDelete={true}
                    editRoute={`/edit-item/${item.id}`}
                    onClickDelete={onClickDelete}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ItemListComponent
