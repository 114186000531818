import React, { useState } from "react"
import { Card, CardBody, Collapse, Table } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ProviderReceiptReportFilter from "./filter"
import ProviderConsumptionReporFilter from "./filter"
import Chart2 from "components/Charts/Chart2"
import ProviderConsumptionReporFilter2 from "./filter"
import { getMonthName } from "utils/utils"
import ProviderConsumptionHeaderTitle from "./Header"
import PaginationLinks from "components/Pagination"

const ProviderConsumptionReportListComponent2 = ({
  data,
  error,
  loading,
  onChangeOrdering,
  onExportBtnClick,
  onSearchHandler,
  exportStatus,
  onPageClick,
  onChangeRowNumber,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)
  const [filter, setFilter] = useState({})

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  const onAdvancedSearchSubmit = data => {
    setFilter(data)
    onSearchHandler(data)
  }

  let consumptData = []
  if (data) {
    consumptData = data?.data?.map(stock => {
      const items = stock.item_name
      const in_qty = !isNaN(parseInt(stock.totalQty))
        ? parseInt(stock.totalQty)
        : 0

      const out_qty = !isNaN(parseInt(stock.ProviderTotalConsums))
        ? parseInt(stock.ProviderTotalConsums)
        : 0

      const balance = in_qty - out_qty
      return { items, in_qty, out_qty, balance: balance }
    })
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            pageRowNumberOptions={[20, 100, 150]}
            title={"CONSUMPTION REPORTS 2s"}
            lgColumn={6}
            exportBtn={true}
            exportBtnLoading={exportStatus?.loading}
            onExportBtnClick={onExportBtnClick}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={false}
            rightBtn={false}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "date", name: "Date" },
              //   { value: "date", name: "Submission Date" },
            ]}
          />
          <Collapse isOpen={filterOpen}>
            <ProviderConsumptionReporFilter2
              loading={loading}
              error={error}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>

          <div className="table-responsive">
            <ProviderConsumptionHeaderTitle error={error} />
            <Table className="table mb-0">
              <thead>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>SN</th>
                  <th style={{ whiteSpace: "nowrap" }}>IP</th>
                  <th style={{ whiteSpace: "nowrap" }}>PROVINCE</th>
                  <th style={{ whiteSpace: "nowrap" }}>HEALTH FACILITY</th>
                  <th style={{ whiteSpace: "nowrap" }}>HEALTH FACILITY TYPE</th>
                  <th style={{ whiteSpace: "nowrap" }}>HMIS Code</th>
                  <th style={{ whiteSpace: "nowrap" }}>ITEM CATEGORY</th>
                  <th style={{ whiteSpace: "nowrap" }}>ITEM</th>
                  <th
                    style={{ whiteSpace: "nowrap", color: "rgba(0, 170, 85)" }}
                  >
                    OPENING BALANCE
                  </th>
                  <th style={{ whiteSpace: "nowrap" }}>
                    MONTHS OF CONSUMPTION
                  </th>

                  <th
                    style={{ whiteSpace: "nowrap", color: "rgba(243, 32, 19)" }}
                  >
                    TOTAL CONSUMPTION
                  </th>
                  <th style={{ whiteSpace: "nowrap", color: "rgba(0,0,255)" }}>
                    BALANCE
                  </th>
                  {/* <th style={{ whiteSpace: "nowrap" }} className="text-info">
                    Percentage
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((consumption, index) => {
                  const balance =
                    consumption?.totalQty - consumption?.ProviderTotalConsums
                  const percentage =
                    (consumption?.ProviderTotalConsums /
                      consumption?.totalQty) *
                    100
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{consumption?.ip?.name}</td>
                      <td>{consumption?.province?.province}</td>
                      <td>{consumption?.facility?.facility_name}</td>
                      <td>{consumption?.facility?.facility_type}</td>
                      <td>{consumption?.facility?.facility_code}</td>
                      <td>{consumption?.item_category?.name}</td>

                      <td>{consumption?.item.ip_item_description}</td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          color: "rgba(0, 170, 85)",
                        }}
                      >
                        {consumption?.totalQty}
                      </td>
                      {consumption?.ProviderTotalConsumsBasedOfMonth?.length >
                      0 ? (
                        <td>
                          <Table bordered={true}>
                            <thead>
                              <tr>
                                {consumption?.ProviderTotalConsumsBasedOfMonth.map(
                                  (month, key) => (
                                    <th key={key}>
                                      {getMonthName(month.month)}
                                    </th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {consumption?.ProviderTotalConsumsBasedOfMonth.map(
                                  (month, key) => (
                                    <th key={key}>{month.total}</th>
                                  )
                                )}
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      ) : (
                        <td>0</td>
                      )}

                      <td
                        style={{
                          whiteSpace: "nowrap",
                          color: "rgba(243, 32, 19)",
                        }}
                      >
                        {consumption?.ProviderTotalConsums}
                      </td>
                      <td
                        style={{ whiteSpace: "nowrap", color: "rgba(0,0,255)" }}
                      >
                        {balance}
                      </td>
                      {/* <td className="text-info">{percentage.toFixed(1)} %</td> */}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            {data?.meta && (
              <PaginationLinks meta={data?.meta} onPageClick={onPageClick} />
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderConsumptionReportListComponent2
