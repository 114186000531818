import React, { useEffect, useState } from "react"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  getProviderIps,
  getProviderIpsStock,
  getProviderItemCategories,
  getProviderItems,
  getProviderStock,
} from "store/provider-dashboard/provider-dashboard-action"
import ProviderDashboardComponent from "components/ProviderDashboard/ProviderDashboardComponent"
import ProviderInventoryListComponent from "components/ProviderReports/Inventory"
import { exportProviderInventoryReport } from "store/provider-reports/p-report-action"

const ProviderInventoryList = props => {
  //meta title
  document.title = "Dashboard"

  const [params, setParams] = useState({})
  const providerStock = useSelector(
    state => state.providerDashboard.providerStock
  )

  const dispatch = useDispatch()

  const onSubmit = values => {
    setParams(values)
    dispatch(getProviderStock(values))
  }

  const onExport = () => {
    dispatch(exportProviderInventoryReport(params))
  }

  const onCategoryChange = category => {
    dispatch(getProviderItems(category))
  }

  useEffect(() => {
    dispatch(getProviderStock())
    dispatch(getProviderIps())
    dispatch(getProviderItemCategories())
  }, [])

  return (
    <React.Fragment>
      <Breadcrumbs title={"Provider"} breadcrumbItem={"Inventory"} />
      <ProviderInventoryListComponent
        provStock={providerStock?.data?.data?.providerReports}
        onSubmit={onSubmit}
        onCategoryChange={onCategoryChange}
        onExport={onExport}
      />
    </React.Fragment>
  )
}

export default ProviderInventoryList
