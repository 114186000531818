import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"
import CSAlert from "components/Alert/ConfirmationAlert"
import { result } from "lodash"
import SAlert from "components/Alert"
import ProviderInFilter from "./DistributionFilter"
import MonitorDistributionFilter from "./DistributionFilter"
import ProviderOutTotalBox from "components/ProviderOut/ProviderOutTotalBox"
import { downloadURL } from "api/APP_URL"

const MonitorDistributionComponents = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onAdvancedSearchSubmit,
  onClickDetail,
  onExportBtnClick,
  exportStatus,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            rightBtn={false}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "ip_id", name: "IP" },
              { value: "province_id", name: "Province" },
              { value: "date", name: "Submission Date" },
            ]}
          />
          <ProviderOutTotalBox totals={data?.totals} />
          <Collapse isOpen={filterOpen}>
            <MonitorDistributionFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={[
              "SN",
              "PROJECT",
              "PROVIDER",
              "PROVIDER IP",
              "IP",
              "DISTRIBUTOR",
              "WAREHOUSE DISRIBUTE",
              "PROVINCE",
              "FACILITY",
              "UNFPA ITEM ID",
              "IP- ITEM DESCRIPTION",
              "UNFPA UNIT OF MEASURE (UOM))",
              "IP UNIT UOM",
              "UNIT PRICE (AS PER UNFPA)",
              "UNIT PRICE (AS PER IP UOM)",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "DISTRIBUTED QTY PER IP	",
              "DISTRIBUTION DATE",
              "ACTION",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(pOut, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{pOut?.project?.name}</td>
                <td>{pOut?.provider?.title}</td>
                <td>{pOut?.ProvderIP?.name}</td>
                <td>{pOut?.ip?.name}</td>
                <td>{pOut?.distributor}</td>
                <td>{pOut?.warehouse_disribute}</td>
                <td>{pOut?.province?.province}</td>
                <td>{pOut?.facility?.facility_name}</td>
                <td>{pOut?.item?.unfp_item_name}</td>
                <td>{pOut?.item?.ip_item_description}</td>
                <td>
                  {pOut?.item.uom} &nbsp;
                  {pOut?.item.unfpa_uom_quantity > 1 &&
                    pOut?.item.unfpa_uom_quantity}
                </td>
                <td>
                  {pOut?.item.ip_unit_umo} &nbsp;
                  {pOut?.item.ip_uom_quantity > 1 && pOut?.item.ip_uom_quantity}
                </td>
                <td>{pOut?.provider_in?.unit_price_per_unfpa}</td>
                <td>{pOut?.provider_in?.unit_price_per_ip}</td>
                <td>{pOut?.provider_in?.batch_number}</td>
                <td>{pOut?.provider_in?.expire_date}</td>
                <td>{pOut?.out_quantity}</td>
                <td>{pOut?.date}</td>
                <td>
                  <ActionColumns
                    data={pOut}
                    enableDetail={true}
                    onClickDetail={onClickDetail}
                    enableDocumentLink={pOut?.document}
                    documentLink={`${downloadURL}/${pOut?.document}`}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonitorDistributionComponents
