import axiosInstance from "api/axiosInstance"
import { providerReportsAction } from "./p-report-slice"
import FileSaver from "file-saver"
import exportAxiosInstance from "api/exportAxiosInstance"

export const getProviderTransactions =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setTransactionReport({
        loading: true,
      })
    )
    url = url || "/v1/get-provider-transaction-reports"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            providerReportsAction.setTransactionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setTransactionReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getProviderReceiptReports =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderReceiptReport({
        loading: true,
      })
    )
    url = url || "/v1/get-provider-receipt-reports"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            providerReportsAction.setProviderReceiptReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderReceiptReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getProviderReceiptHealthReports =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderReceiptHealthReport({
        loading: true,
      })
    )
    url = url || "/v1/get-provider-receipt-health-report"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            providerReportsAction.setProviderReceiptHealthReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderReceiptHealthReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getProviderDistributionReports =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderDistributionReport({
        loading: true,
      })
    )
    url = url || "/v1/get-provider-distribution-reports"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            providerReportsAction.setProviderDistributionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderDistributionReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getProviderProvinceDistributionReports =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderProvicneDistributionReport({
        loading: true,
      })
    )
    url = url || "/v1/get-provider-province-distributions"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            providerReportsAction.setProviderProvicneDistributionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderProvicneDistributionReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getProviderConsumptionReports =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderConsumptionReport({
        loading: true,
      })
    )
    advancedSearch.according = according
    advancedSearch.pageNumber = pageNumber
    url = url || "/v1/get-provider-consumptions-report"
    axiosInstance
      .get(url, {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          dispatch(
            providerReportsAction.setProviderConsumptionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderConsumptionReport({
            loading: false,
            error: error?.response?.data,
            data: null,
          })
        )
      })
  }

export const getProviderConsumptionReports2 =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderConsumptionReport({
        loading: true,
      })
    )
    advancedSearch.according = according
    advancedSearch.pageNumber = pageNumber
    url = url || "/v1/get-provider-consumptions-report-two"
    axiosInstance
      .get(url, {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          dispatch(
            providerReportsAction.setProviderConsumptionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderConsumptionReport({
            loading: false,
            error: error?.response?.data,
            data: null,
          })
        )
      })
  }

export const getProviderQuantificationReport = params => dispatch => {
  dispatch(
    providerReportsAction.setProviderQuantificationReport({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-quantification-report`, {
      params: params ? params : {},
    })
    .then(respnose => {
      dispatch(
        providerReportsAction.setProviderQuantificationReport({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerReportsAction.setProviderQuantificationReport({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}
export const getProviderQuantificationReportBasedOrganization =
  (url, params = {}) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderQuantificationReport({
        loading: true,
        error: null,
        data: null,
      })
    )

    url = url || `/v1/get-provider-quantification-report-baseOrganization`
    axiosInstance
      .get(url, {
        params: params,
      })
      .then(respnose => {
        dispatch(
          providerReportsAction.setProviderQuantificationReport({
            loading: false,
            error: null,
            data: respnose?.data,
          })
        )
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderQuantificationReport({
            loading: false,
            error: error?.response,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const exportProviderTransactions =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setTransactionExport({
        loading: true,
        error: null,
        data: null,
      })
    )
    exportAxiosInstance
      .get("/v1/get-provider-transaction-export", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Transaction-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerReportsAction.setTransactionExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setTransactionExport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }
export const exportProviderTransactionsWithProject =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setTransactionExport({
        loading: true,
        error: null,
        data: null,
      })
    )
    exportAxiosInstance
      .get("/v1/get-provider-transaction-export-with-project", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Transaction-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerReportsAction.setTransactionExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setTransactionExport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const exportProviderReceiptReport =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setReceiptReportExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/get-provider-receipt-export", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Receipt-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerReportsAction.setReceiptReportExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setReceiptReportExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const exportProviderDistributionReport =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setDistributionReportExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/get-provider-distribution-export", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Distribution-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerReportsAction.setDistributionReportExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setDistributionReportExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const exportProviderProvinceDistributionReport =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProvinceDistributionReportExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/get-provider-province-distributions-export", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `IP-Distribution-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerReportsAction.setProvinceDistributionReportExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProvinceDistributionReportExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const exportProviderInventoryReport = params => dispatch => {
  dispatch(
    providerReportsAction.setProviderInventoyExport({
      loading: true,
      error: null,
    })
  )
  exportAxiosInstance
    .get("/v1/get-provider-inventory-export", {
      params: params ? params : {},
    })
    .then(response => {
      if (response) {
        const dataBlob = new Blob([response?.data], {
          type: "application/pdf,",
        })
        FileSaver.saveAs(
          dataBlob,
          `Provider-Inventory-Report-${Math.random().toString()}.xlsx`
        )
        dispatch(
          providerReportsAction.setProviderInventoyExport({
            loading: false,
            error: null,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        providerReportsAction.setProviderInventoyExport({
          loading: false,
          error: error?.response?.errors,
        })
      )
      console.log(error)
    })
}

export const exportProviderConsumptionReports =
  (according = { order: "desc", name: "date" }, advancedSearch = {}) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderConsumptionExport({
        loading: true,
        error: null,
      })
    )
    advancedSearch.according = according
    exportAxiosInstance
      .get("/v1/export-provider-consumptions-report", {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Provider-Consumptions-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerReportsAction.setProviderConsumptionExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderConsumptionExport({
            loading: false,
            error: error?.response?.data,
          })
        )
      })
  }

export const exportProviderConsumptionReports2 =
  (according = { order: "desc", name: "date" }, advancedSearch = {}) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderConsumptionExport({
        loading: true,
        error: null,
      })
    )
    advancedSearch.according = according
    exportAxiosInstance
      .get("/v1/export-provider-consumptions-report-two", {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Provider-Consumptions-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerReportsAction.setProviderConsumptionExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderConsumptionExport({
            loading: false,
            error: error?.response?.data,
          })
        )
      })
  }

export const exportProviderReceiptHealthReport =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderReceiptHealthExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/export-provider-receipt-health-report", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Receipt-Health-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerReportsAction.setProviderReceiptHealthExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderReceiptHealthExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const exportProviderQuantificationReport =
  (params = {}) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderQuantificationExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/export-provider-quantification-report", {
        params: params,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Provider-Quantification-report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerReportsAction.setProviderQuantificationExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderQuantificationExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const exportProviderQuantificationReportBasedOrganization =
  (params = {}) =>
  dispatch => {
    dispatch(
      providerReportsAction.setProviderQuantificationExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/export-provider-quantification-report-baseOrganization", {
        params: params,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Provider-Quantification-based-organization-report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerReportsAction.setProviderQuantificationExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerReportsAction.setProviderQuantificationExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }
