import TableContainer from "components/Common/TableContainer"
import React, { useMemo, useRef, useState } from "react"
import { Button, Input } from "reactstrap"
import { isNumber } from "lodash"
import { getProvinceQuantityFromSubmited } from "utils/provinceOutUtils"

const ProvinceReceiptsList = ({ data, onSubmitAddHandler, submited }) => {
  const [outQuantity, setOutQuantity] = useState([])
  const inputRef = useRef()

  const handleChangeQuantity = (e, receipt) => {
    if (e.target.value < 0 && !isNumber(e.target.value)) {
      return
    }

    inputRef.current = receipt.unique_id
    const newItem = { id: receipt.unique_id, quantity: e.target.value }
    setOutQuantity(prev =>
      prev.some(p => p.id === receipt.unique_id)
        ? prev.map(qty =>
            qty.id === newItem.id ? { ...qty, quantity: e.target.value } : qty
          )
        : [...prev, newItem]
    )
  }

  function showTotal(quantity, id) {
    const value = outQuantity.find(qty => qty.id === id)

    if (value) {
      return (
        parseInt(quantity) -
        getProvinceQuantityFromSubmited(submited, id) -
        parseInt(Math.abs(value.quantity))
      )
    } else {
      return quantity - getProvinceQuantityFromSubmited(submited, id)
    }
  }

  const onAddHandler = (outQuantityValue, receipt) => {
    const quantity = parseInt(outQuantityValue.quantity)

    const result = {
      out_quantity: quantity,
      province_in_id: outQuantityValue.id,
      batch_number: receipt?.batch_number,
      project_id: receipt?.project_id,
      province_id: receipt?.province_id,
    }

    onSubmitAddHandler(result)
    onResetReceiptList()
  }

  const onResetReceiptList = () => {
    setOutQuantity([])
    inputRef.current.value = ""
  }

  function checkForAdd(receipt) {
    const outQuantityValue = outQuantity.find(
      qty => qty.id === receipt?.unique_id
    )

    if (outQuantityValue) {
      const total = showTotal(receipt?.in_qty, receipt?.unique_id)
      if (total >= 0 && total !== NaN && total <= receipt?.in_qty) {
        if (
          total !== receipt?.in_qty &&
          getProvinceQuantityFromSubmited(submited, receipt?.unique_id) !==
            receipt?.in_qty
        ) {
          return (
            <Button
              className="btn btn-sm btn-info"
              onClick={() => onAddHandler(outQuantityValue, receipt)}
              type="button"
            >
              Add
            </Button>
          )
        } else {
          return false
        }
      } else {
        return <span className="text-danger">Out of Quantity</span>
      }
    } else {
      return
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "UNFPA ITEM ID",
        accessor: "item.unfp_item_name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value
        },
      },
      {
        Header: "IP- ITEM DESCRIPTION",
        accessor: "item.ip_item_description",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value
        },
      },
      {
        Header: "UNFPA UOM",
        accessor: "item",
        id: "unpfa_item",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return `${cellProps?.value?.uom} ${
            cellProps?.value?.unfpa_uom_quantity > 1
              ? cellProps?.value?.unfpa_uom_quantity
              : ""
          }`
        },
      },
      {
        Header: "IP UOM",
        accessor: "item",
        id: "ip_item",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return `${cellProps?.value?.ip_unit_umo} ${
            cellProps?.value?.ip_uom_quantity > 1
              ? cellProps?.value?.ip_uom_quantity
              : ""
          }`
        },
      },
      {
        Header: "BATCH NUMBER",
        accessor: "batch_number",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value
        },
      },
      {
        Header: "Province",
        accessor: "province",
        id: "province",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return row?.original?.province?.province
        },
      },
      {
        Header: "HFs",
        accessor: "hfs",
        id: "hfs",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return row?.original?.facility?.facility_name
            ? row?.original?.facility?.facility_name
            : "Warehouse"
        },
      },
      {
        Header: "HFs Type",
        accessor: "hfsType",
        id: "hfsType",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return row?.original?.facility?.facility_type
        },
      },
      {
        Header: "HFs Code",
        accessor: "HFsCode",
        id: "HFsCode",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return row?.original?.facility?.facility_code
        },
      },
      {
        Header: "PROJECT",
        accessor: "project",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value?.name
        },
      },
      {
        Header: "EXPIRE DATE",
        accessor: "expire_date",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value
        },
      },
      {
        Header: "Quantity",
        accessor: "in_qty",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value
        },
      },
      {
        Header: "Out",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <Input
              key={row?.original?.unique_id}
              type="number"
              onWheel={e => e.target.blur()}
              name={`quantity-${row?.original.unique_id}`}
              ref={inputRef}
              min={0}
              value={
                outQuantity?.find(qty => qty.id === row?.original?.unique_id)
                  ?.quantity
              }
              onChange={e => handleChangeQuantity(e, row?.original)}
              autoFocus={row?.original?.unique_id === inputRef.current}
              style={{ width: 100 }}
            />
          )
        },
      },
      {
        Header: "Balance",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return showTotal(row?.original?.in_qty, row?.original?.unique_id)
        },
      },

      {
        Header: "",
        id: "addDistribute",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return checkForAdd(row?.original)
        },
      },
    ],
    [handleChangeQuantity, checkForAdd]
  )

  return (
    <React.Fragment>
      <div className="mb-4 h4 card-title">Items</div>
      <TableContainer
        columns={columns}
        data={data}
        isGlobalFilter={false}
        isAddOptions={false}
        customPageSize={6}
      />
    </React.Fragment>
  )
}

export default ProvinceReceiptsList
