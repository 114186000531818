import PropTypes from "prop-types"
import React, { useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { useDispatch, useSelector } from "react-redux"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import logoLight from "../../assets/images/logos/log_bg_less.png"
import { login } from "store/auth/auth-actions"
import Preloader from "components/Common/Loader"
import { getAuthToken } from "utils/authUtil"
import PasswordInput from "components/Common/PasswordInput"

const Login = props => {
  //meta title
  document.title = "eLMIS Login"
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { error, isLoggedIn, loading } = useSelector(state => state.auth)

  const token = getAuthToken()

  if (token) {
    navigate(-1)
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .email("Input it shoud be email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(login(values))(() => {
        navigate("/dashboard")
      })
    },
  })

  return (
    <React.Fragment>
      {!token ? (
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome !</h5>
                          <p>Sign in to eLMIS.</p>
                        </div>
                      </Col>
                      <Col
                        className="col-5 align-self-center"
                        style={{ textAlign: "right" }}
                      >
                        <img
                          src={logoLight}
                          alt=""
                          style={{ width: 100, marginRight: 10 }}
                          className="img-fluid"
                        />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {error?.error ? (
                          <Alert color="danger">{error?.error}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label">Password</Label>
                              <PasswordInput
                                name="password"
                                value={validation.values.password || ""}
                                type="password"
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Please wait..." : "Log In"}
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} eLMIS by{" "}
                    <a href="https://www.aryanict.com/">Aryan ICT Solutions</a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
