import React, { useMemo, useRef, useState } from "react"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { useSelector, useDispatch } from "react-redux"
import { isObject } from "lodash"
import Select from "react-select"
import { getMonitorItems } from "store/monitor-dashboard/monitor-dashboard-action"

const MonitorReceiptReportFilter = ({ onAdvancedSearchSubmit, loading }) => {
  const dateFlatpickerfrom = useRef(null)
  const dateFlatpickerto = useRef(null)
  const [category, setCategory] = useState(null)
  const [item, setItem] = useState(null)
  const [batch_number, setBatchNumber] = useState("")
  const [ip, setIP] = useState(null)

  const dispatch = useDispatch()

  const [date, setDate] = useState({
    from: null,
    to: null,
  })

  const categories = useSelector(
    state => state.monitorDashboard.MonitorItemCategoires
  )

  const items = useSelector(state => state.monitorDashboard.MonitorItems)
  const ips = useSelector(state => state.monitorDashboard.MonitorIps)

  const warehouses = useSelector(
    state => state.monitorDashboard.monitorWarehouses
  )

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const handleSubmit = () => {
    let data = {}

    data.date = {
      from: date.from ? date.from : null,
      to: date.to ? date.to : null,
    }
    data.item = isObject(item) ? item.value : null
    data.providerIP = isObject(ip) ? ip.value : null
    data.category = isObject(category) ? category.value : null
    data.batchNumber = batch_number
    onAdvancedSearchSubmit(data)
  }

  const onClearHandler = () => {
    dateFlatpickerfrom.current.flatpickr.clear()
    dateFlatpickerto.current.flatpickr.clear()
    setItem(null)
    setCategory(null)
    setBatchNumber("")
    setDate({ from: null, to: null })
    setIP(null)
    onAdvancedSearchSubmit({})
  }

  const onCategoryChangeHandler = cate => {
    setCategory(cate)
    dispatch(getMonitorItems(cate.value))
  }

  const onItemChangeHandler = item => {
    setItem(item)
  }

  const onBatchNumberChange = batch_number => {
    setBatchNumber(batch_number.target.value)
  }

  const onIPChangeHandler = ips => {
    setIP(ips)
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Providers</Label>
            {ips?.data && (
              <Select
                options={ips?.data}
                value={ip}
                onChange={onIPChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Item Categories</Label>
            {categories?.data && (
              <Select
                options={categories?.data}
                value={category}
                onChange={onCategoryChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Items</Label>
            {filteredItems && (
              <Select
                options={[{ value: "all", label: "All" }, ...filteredItems]}
                value={item}
                onChange={onItemChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Batch Number</Label>
            <Input
              name="batch_number"
              placeholder="Batch Number"
              type="text"
              onChange={onBatchNumberChange}
              value={batch_number}
            />
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Label className="form-label">Date</Label>
        <Row>
          <Col>
            <Flatpickr
              ref={dateFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Flatpickr
              ref={dateFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default MonitorReceiptReportFilter
