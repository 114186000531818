import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import {
  deleteManyProviderIn,
  deleteProviderIn,
  getProviderIns,
} from "store/provider-in/provider-in-action"
import ProviderInListComponent from "components/ProviderIn/list"
import SAlert from "components/Alert"
import { getProviderItemCategories } from "store/provider-dashboard/provider-dashboard-action"
import useSearch from "hooks/useSearch"
import {
  exportProvinceIn,
  getProvinceInProjects,
  getProvinceIns,
} from "store/province-in/province-in-action"
import {
  getProvinceItemCategories,
  getProvinceProjects,
} from "store/province-dashboard/province-dashboard-action"
import ProvinceInListComponent from "components/ProvinceIn/list"
import ProvinceInDetailModal from "components/ProvinceIn/details"

const ProvinceInList = () => {
  document.title = "Receipts"

  const dispatch = useDispatch()
  const { data, error, loading, exportStatus } = useSelector(
    state => state.provinceIn
  )
  const permissions = useSelector(state => state.auth.permissions)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })
  const [advnacedSearch, setAdvancedSearch] = useState({})
  const [detailModal, setDetailModal] = useState(false)
  const [pIn, setPIn] = useState(null)

  const onPageClick = link => {
    dispatch(
      getProvinceIns(link.url, pageNumber, according, search, advnacedSearch)
    )
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getProvinceIns(undefined, undefined, undefined, search, advnacedSearch),
    search
  )

  useEffect(() => {
    dispatch(
      getProvinceIns(undefined, pageNumber, according, search, advnacedSearch)
    )
  }, [pageNumber, according])

  useEffect(() => {
    dispatch(getProvinceItemCategories())
    dispatch(getProvinceInProjects())
  }, [])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(getProvinceIns(undefined, undefined, undefined, search, data))
  }

  const onClickDetail = data => {
    setPIn(data)
    setDetailModal(true)
  }

  const onExportBtnClick = () => {
    dispatch(exportProvinceIn(advnacedSearch))
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Province"} breadcrumbItem={"Receipts"} />
      <ProvinceInDetailModal
        data={pIn}
        open={detailModal}
        setOpen={setDetailModal}
      />
      <ProvinceInListComponent
        data={data}
        error={error}
        loading={loading}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        permissions={permissions}
        onClickDetail={onClickDetail}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
      />
    </React.Fragment>
  )
}

export default ProvinceInList
