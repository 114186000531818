import axios from "axios"
import { getAuthToken, removeAuthToken, setAuthToken } from "utils/authUtil"

const headers = {}

const BaseURL = "https://afga-elmisbackend.support.af/api"
// const BaseURL = "https://api.elmis.af/api"
// const BaseURL = "http://localhost:8000/api"

const fileaxiosInstance = axios.create({
  baseURL: BaseURL,
  headers,
})

fileaxiosInstance.interceptors.request.use(
  config => {
    const token = getAuthToken()
    if (token) {
      config.headers["Authorization"] = `Bearer ${token.access_token}`
    }
    config.headers["Accept"] = "application/json"
    config.headers["Content-Type"] = "application/json"
    config.headers["Content-Type"] = "multipart/form-data"

    return config
  },
  error => {
    console.log(error)
  }
)

fileaxiosInstance.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    try {
      const token = getAuthToken()
      const originalRequest = error?.config
      if (
        (error?.response?.status === 401 || error?.response?.status === 403) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true
        const response = await axios.post(`${BaseURL}/v1/refresh-token`, {
          refresh_token: token.refresh_token,
        })
        setAuthToken(response?.data?.data)
        return fileaxiosInstance(originalRequest)
      }
      return Promise.reject(error)
    } catch (error) {
      removeAuthToken()
      window.location.href = "/login"
    }
  }
)

export default fileaxiosInstance
