import React, { useState } from "react"
import { Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ProviderProvinceDistributionReportFilter from "./filter"
import MonitorProvinceDistributionReportFilter from "./filter"

const MonitorProvinceDistributionListComponent = ({
  data,
  error,
  loading,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onExportBtnClick,
  onAdvancedSearchSubmit,
  exportTransaction,
  onPageClick,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            pageRowNumberOptions={[20, 100, 150]}
            title={""}
            exportBtn={true}
            exportBtnLoading={exportTransaction?.loading}
            onExportBtnClick={onExportBtnClick}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={false}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "date", name: "Transaction Date" },
              //   { value: "date", name: "Submission Date" },
            ]}
          />
          <Collapse isOpen={filterOpen}>
            <MonitorProvinceDistributionReportFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={[
              "PROVIDER",
              "IP",
              "TRANSACTION DATE",
              "TRANSACTION TYPE",
              "TRANSACTION SUB-TYPE",
              "UNFPA ITEM ID",
              "P- ITEM DESCRIPTION",
              "UNFPA UOM",
              "IP UOM",
              "PROVINCE",
              "FACILITY",
              "BATCH / LOT NUMBER",
              "EXPIRY / BEST BEFORE DATE",
              "QUANTITY",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(transaction, index) => (
              <tr key={index}>
                <td style={{ whiteSpace: "nowrap" }}>
                  {transaction?.provider?.title}
                </td>
                <td> {transaction?.ip?.name}</td>
                <td style={{ whiteSpace: "nowrap" }}>{transaction?.date}</td>
                <td style={{ whiteSpace: "nowrap" }}>{transaction?.type}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {transaction?.type === "Receipts" ? "From UNFPA" : "External"}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {transaction?.item?.unfp_item_name}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {transaction?.item?.ip_item_description}
                </td>

                <td>
                  {transaction?.item?.uom} &nbsp;
                  {transaction?.item?.unfpa_uom_quantity > 1 &&
                    transaction?.item?.unfpa_uom_quantity}
                </td>
                <td>
                  {transaction?.item?.ip_unit_umo} &nbsp;
                  {transaction?.item?.ip_uom_quantity > 1 &&
                    transaction?.item?.ip_uom_quantity}
                </td>
                <td>{transaction?.province?.province}</td>
                <td>{transaction?.facility?.facility_name}</td>
                <td>{transaction?.province_in?.batch_number}</td>
                <td>{transaction?.province_in?.expire_date}</td>
                <td>{transaction?.qty}</td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonitorProvinceDistributionListComponent
