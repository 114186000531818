import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Collapse } from "reactstrap"
import { getTotalExpireReceiptsCount } from "store/provider-exipre-receipts/e-expire-receipts-action"
import { getProviderStockOutCount } from "store/provider-stock-out/provider-stockout-action"
import { getProvinceActionRequestCount } from "store/province-action-requests/province-action-request-action"

const ProviderNavbar = props => {
  const [report, setReport] = useState(false)
  const [expireList, setExpireList] = useState(false)

  const dispatch = useDispatch()

  const totalExpireCount = useSelector(
    state => state.providerExpireReceipts.count
  )
  const totalStockOut = useSelector(state => state.providerStockout.count)
  const totalRFA = useSelector(
    state => state.provinceActionRequest.ActionRequestCount.distribution
  )

  useEffect(() => {
    dispatch(getTotalExpireReceiptsCount())
    dispatch(getProviderStockOutCount())
    dispatch(getProvinceActionRequestCount())
  }, [])

  return (
    <Collapse
      isOpen={props.leftMenu}
      className="navbar-collapse"
      id="topnav-menu-content"
    >
      <ul className="navbar-nav">
        <li className="nav-item ">
          <Link className="nav-link " to="/dashboard">
            <i className="bx bx-home-circle me-2"></i>
            Dashboard
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/provider-receipts">
            <i className="bx bx-home-circle me-2"></i>
            Receipts ( In )
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/provider-distributions">
            <i className="bx bx-home-circle me-2"></i>
            Distributions ( Out )
          </Link>
        </li>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle arrow-none"
            onClick={e => {
              e.preventDefault()
              setExpireList(!expireList)
            }}
            to="#"
            style={
              totalExpireCount?.countPerMonth > 0 ||
              totalExpireCount?.countPerYear > 0
                ? { color: "red" }
                : {}
            }
          >
            <i className="mdi mdi-order-bool-descending me-2"></i>
            Expire Lists{props.menuOpen}
            <div className="arrow-down"></div>
          </Link>
          <div className={"dropdown-menu"}>
            <Link to="/provider-expire-receipts" className="dropdown-item">
              Expire List / 6 Month &nbsp;
              <span className="text-danger" style={{ fontSize: 11 }}>
                ( {totalExpireCount?.countPerMonth} )
              </span>
            </Link>

            <Link
              to="/provider-expire-receipts-base-12-month"
              className="dropdown-item"
            >
              Expire List / 12 Month &nbsp;
              <span className="text-danger" style={{ fontSize: 11 }}>
                ( {totalExpireCount?.countPerYear} )
              </span>
            </Link>
          </div>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/provider-consumptions">
            <i className="bx bx-home-circle me-2"></i>
            Consumptions
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/provider-stock-out">
            <i className="bx bx-home-circle me-2"></i>
            Stock Out &nbsp;
            <span className="text-danger" style={{ fontSize: 11 }}>
              ( {totalStockOut} )
            </span>
          </Link>
        </li>

        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle arrow-none"
            onClick={e => {
              e.preventDefault()
              setReport(!report)
            }}
            to="#"
          >
            <i className="mdi mdi-order-bool-descending me-2"></i>
            Reports {props.menuOpen}
            <div className="arrow-down"></div>
          </Link>
          <div className={"dropdown-menu"}>
            <Link to="/provider-transactions" className="dropdown-item">
              All Transactions ( In / Out )
            </Link>
            <Link
              to="/provider-transactions-by-project"
              className="dropdown-item"
            >
              All Transactions ( In / Out ) By Project
            </Link>
            <Link to="/provider-receipt-reports" className="dropdown-item">
              Receipts ( In )
            </Link>
            <Link to="/provider-distribution-reports" className="dropdown-item">
              Distributions ( Out )
            </Link>
            <Link
              to="/provider-ip-distribution-reports"
              className="dropdown-item"
            >
              IP Distributions ( Out )
            </Link>
            <Link to="/provider-inventory-reports" className="dropdown-item">
              Inventory
            </Link>
            <Link
              to="/provider-consumptions-reports-second"
              className="dropdown-item"
            >
              Consumptions
            </Link>
            <Link
              to="/provider-receipt-health-reports"
              className="dropdown-item"
            >
              Receipt Health ( In )
            </Link>
            <Link
              to="/provider-quantification-report"
              className="dropdown-item"
            >
              Quantification Report
            </Link>
            <Link
              to="/provider-quantification-based-organization-report"
              className="dropdown-item"
            >
              Quantification Based Organization Report
            </Link>
          </div>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/provider-province-action-request">
            RFA &nbsp;{" "}
            <span className="text-danger" style={{ fontSize: 11 }}>
              ( {totalRFA} )
            </span>
          </Link>
        </li>
      </ul>
    </Collapse>
  )
}

export default ProviderNavbar
