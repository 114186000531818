import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import MonitorChartOutItemsList from "components/MonitorDashboard/MonitorItems/MonitorChartOutItemsList"
import { getMonitorOutItems } from "store/monitor-dashboard/monitor-dashboard-action"
import { monitorDashboardAction } from "store/monitor-dashboard/monitor-dashboard-slice"

const MonitorChartOutItems = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const getParams = {
    itemId: searchParams.get("itemId"),
    from: searchParams.get("from") !== "null" ? searchParams.get("from") : null,
    providerIP:
      searchParams.get("providerIP") !== "null"
        ? searchParams.get("providerIP")
        : null,
    to: searchParams.get("to") !== "null" ? searchParams.get("to") : null,
  }
  const MonitorOutItems = useSelector(
    state => state.monitorDashboard.MonitorOutItems
  )

  useEffect(() => {
    dispatch(getMonitorOutItems(getParams))

    return () => {
      dispatch(monitorDashboardAction.resetMonitorOutItems())
    }
  }, [])

  return (
    <MonitorChartOutItemsList
      data={MonitorOutItems?.data?.data}
      loading={MonitorOutItems?.loading}
    />
  )
}

export default MonitorChartOutItems
