import fileaxiosInstance from "api/fileAxiosInstance"
import SAlert from "components/Alert"
import { batch } from "react-redux"
import { providerInAction } from "./provider-in-slice"
import axiosInstance from "api/axiosInstance"

export const getProviderIns =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(providerInAction.setLoading(true))
    url = url || "/v1/provider-ins"
    fileaxiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(providerInAction.setError(null))
            dispatch(providerInAction.setData(response.data))
            dispatch(providerInAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(providerInAction.setLoading(false))
        console.log(error)
      })
  }

export const deleteManyProviderIn = ids => dispatch => {
  dispatch(providerInAction.setLoading(true))
  fileaxiosInstance
    .post("/v1/provider-ins/delete/ins", { ids })
    .then(response => {
      dispatch(getProviderIns())
      SAlert({ title: "Success", text: "Receipts successfully delete" })
      batch(() => {
        dispatch(providerInAction.setError(null))
        dispatch(providerInAction.setLoading(false))
      })
    })
    .catch(error => {
      if (error?.response?.data?.deleteError) {
        SAlert({
          text: error?.response?.data?.deleteError,
          status: "warning",
          timer: 5000,
        })
      }
      console.log(error)
    })
}

export const uploadFileForManyReceipts = forms => dispatch => {
  dispatch(
    providerInAction.setUploadFile({
      loading: true,
      error: null,
    })
  )
  fileaxiosInstance
    .post("/v1/provider-ins/upload/file-receipts", forms)
    .then(response => {
      dispatch(getProviderIns())
      dispatch(
        providerInAction.setUploadFile({
          loading: false,
          error: null,
        })
      )
      SAlert({ title: "Success", text: "File Successfully uploaded" })
    })
    .catch(error => {
      dispatch(
        providerInAction.setUploadFile({
          loading: false,
          error: null,
        })
      )
      if (error.response?.data?.errors?.document?.[0]) {
        SAlert({
          text: error.response?.data?.errors?.document?.[0],
          status: "warning",
          timer: 4000,
        })
      }
    })
}

export const deleteProviderIn = id => dispatch => {
  dispatch(providerInAction.setLoading(true))
  fileaxiosInstance
    .delete(`/v1/provider-ins/${id}`)
    .then(response => {
      console.log(response?.data)
      dispatch(getProviderIns())
      SAlert({ title: "Success", text: "Receipt successfully delete" })
      batch(() => {
        dispatch(providerInAction.setError(null))
        dispatch(providerInAction.setLoading(false))
      })
    })
    .catch(error => {
      if (error?.response?.data?.deleteError) {
        SAlert({
          text: error?.response?.data?.deleteError,
          status: "warning",
          timer: 5000,
        })
      }
      console.log(error)
    })
}

export const storeProviderIn = (forms, navigate) => dispatch => {
  dispatch(providerInAction.setLoading(true))
  fileaxiosInstance
    .post(`/v1/provider-ins`, forms)
    .then(response => {
      navigate("/provider-receipts")
      SAlert({ title: "Success", text: "Receipt successfully inserted" })
      batch(() => {
        dispatch(providerInAction.setError(null))
        dispatch(providerInAction.setLoading(false))
      })
    })
    .catch(error => {
      console.log("error from provider in")
      dispatch(providerInAction.setLoading(false))
      if (error?.response?.data?.errors) {
        dispatch(providerInAction.setError(error?.response?.data?.errors))
      } else {
        SAlert({ title: "Success", text: "Receipt successfully inserted" })
      }
    })
}

export const updateProviderIn = (id, form, navigate) => dispatch => {
  dispatch(providerInAction.setLoading(true))
  fileaxiosInstance
    .post(`/v1/provider-ins/${id}`, form)
    .then(response => {
      navigate("/provider-receipts")
      SAlert({ title: "Success", text: "Receipt successfully updated" })
      batch(() => {
        dispatch(providerInAction.setError(null))
        dispatch(providerInAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(providerInAction.setLoading(false))
      if (error?.response?.data?.errors) {
        dispatch(providerInAction.setError(error?.response?.data?.errors))
      } else {
        console.log(error?.response)
      }
    })
}

export const getByIdProviderIn = id => dispatch => {
  dispatch(providerInAction.setLoading(true))
  fileaxiosInstance
    .get(`/v1/provider-ins/${id}`)
    .then(response => {
      batch(() => {
        dispatch(providerInAction.setError(null))
        dispatch(providerInAction.setProviderIn(response?.data))
        dispatch(providerInAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(providerInAction.setLoading(false))
      console.log(error)
    })
}

export const getProviderInAvailableItems =
  (params = {}) =>
  dispatch => {
    dispatch(
      providerInAction.setItems({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get(`/v1/get-provider-in-items`, { params })
      .then(respnose => {
        dispatch(
          providerInAction.setItems({
            loading: false,
            error: null,
            data: respnose?.data,
          })
        )
      })
      .catch(error => {
        dispatch(
          providerInAction.setItems({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
        console.log(error)
      })
  }
