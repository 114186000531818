export const MONTHS = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
]

export const DISTRIBUTEDDETAIL = [
  { value: "Warehouse", label: "Warehouse" },
  { value: "HFs", label: "HFs" },
]

export const DEFAULT_DISTRIBUTED_DETAILS = {
  value: "Warehouse",
  label: "Warehouse",
}

export const DISTRIBUTEDTYPE = [
  { value: "IP", label: "IP" },
  { value: "Provider", label: "Provider" },
]

export const QUARTERS = [
  { value: 1, label: "1th Quarter" },
  { value: 2, label: "2th Quarter" },
  { value: 3, label: "3th Quarter" },
  { value: 4, label: "4th Quarter" },
]

export const ROLES = [
  { value: 1, label: "Admin" },
  { value: 2, label: "Provider" },
  { value: 3, label: "Province" },
  { value: 4, label: "Monitor" },
]
