import React, { useMemo, useRef, useState } from "react"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { useSelector, useDispatch } from "react-redux"
import { isObject } from "lodash"
import Select from "react-select"
import { getProvinceItems } from "store/province-dashboard/province-dashboard-action"
import { getProvinceConsumptionsFacilities } from "store/province-consumptions/province-consumption-action"

const ProvinceTransactionFilter = ({ onAdvancedSearchSubmit, loading }) => {
  const dateFlatpickerfrom = useRef(null)
  const dateFlatpickerto = useRef(null)
  const [category, setCategory] = useState(null)
  const [item, setItem] = useState(null)
  const [trType, setTrType] = useState(null)
  const [project, setProject] = useState(null)
  const [providerAccount, setProviderAccount] = useState(null)
  const [province, setProvince] = useState(null)
  const [facility, setFacility] = useState(null)
  const [batch_number, setBatchNumber] = useState("")

  const dispatch = useDispatch()

  const [date, setDate] = useState({
    from: null,
    to: null,
  })

  const categories = useSelector(
    state => state.provinceDashboard.ProvinceItemCategoires
  )

  const projects = useSelector(state => state.provinceIn.projects)

  const providerAccounts = useSelector(
    state => state.provinceConsumptions.provinceConsumpProviderAccount
  )

  const provinces = useSelector(state => state.provinceOut.provinces)

  const facilities = useSelector(
    state => state.provinceConsumptions.provinceConsumpFacilities
  )

  const items = useSelector(state => state.provinceDashboard.ProvinceItems)

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const handleSubmit = () => {
    let data = {}

    data.date = {
      from: date.from ? date.from : null,
      to: date.to ? date.to : null,
    }
    if (item) {
      data.item = item.map(ite => (ite.value ? ite.value : ite))
    }
    data.category = isObject(category) ? category.value : null
    data.trType = isObject(trType) ? trType.value : null
    data.project_id = isObject(project) ? project.value : null
    data.provided_by = isObject(providerAccount) ? providerAccount.value : null
    data.province_id = isObject(province) ? province.value : null
    data.facility = isObject(facility) ? facility.value : null
    data.batch_number = batch_number
    onAdvancedSearchSubmit(data)
  }

  const onClearHandler = () => {
    dateFlatpickerfrom.current.flatpickr.clear()
    dateFlatpickerto.current.flatpickr.clear()
    setItem(null)
    setCategory(null)
    setTrType(null)
    setDate({ from: null, to: null })
    setProject("")
    setProviderAccount("")
    setProvince("")
    setFacility("")
    setBatchNumber("")
    onAdvancedSearchSubmit({})
  }

  const onCategoryChangeHandler = cate => {
    setCategory(cate)
    dispatch(getProvinceItems(cate.value))
  }

  const onItemChangeHandler = item => {
    const check = item.some(ite => ite.value === "all")
    if (check) {
      setItem(filteredItems)
    } else {
      setItem(item)
    }
  }

  const onTransactionTypeChange = type => {
    setTrType(type)
  }

  const onProjectChangeHandler = value => {
    setProject(value)
  }

  const onProviderAccountChangeHandler = value => {
    setProviderAccount(value)
  }

  const onProvinceChangeHandler = value => {
    dispatch(
      getProvinceConsumptionsFacilities({
        province_id: value.value,
      })
    )
    setProvince(value)
  }

  const onFacilityChangeHandler = value => {
    setFacility(value)
  }

  const onBatchNumberChange = batch_number => {
    setBatchNumber(batch_number.target.value)
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">Projects</Label>
              {projects?.data && (
                <Select
                  options={[{ value: "all", label: "All" }, ...projects?.data]}
                  value={project}
                  onChange={onProjectChangeHandler}
                />
              )}
            </div>
          </Col>
          <Col>
            <Label className="form-label">Provider Account</Label>
            {providerAccounts?.data && (
              <Select
                options={providerAccounts?.data}
                value={providerAccount}
                onChange={onProviderAccountChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Province</Label>
            {provinces?.data && (
              <Select
                options={provinces?.data}
                value={province}
                onChange={onProvinceChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Facilities</Label>
            {facilities?.data && (
              <Select
                options={facilities?.data}
                value={facility}
                onChange={onFacilityChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Batch Number</Label>
            <Input
              name="batch_number"
              placeholder="Batch Number"
              type="text"
              onChange={onBatchNumberChange}
              value={batch_number}
            />
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Item Categories</Label>
            {categories?.data && (
              <Select
                options={categories?.data}
                value={category}
                onChange={onCategoryChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Items</Label>
            {filteredItems && (
              <Select
                isMulti={true}
                options={[{ value: "all", label: "All" }, ...filteredItems]}
                value={item}
                onChange={onItemChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Transaction Type</Label>
            {filteredItems && (
              <Select
                options={[
                  { value: "Receipts", label: "Receipts" },
                  { value: "Distribution", label: "Distribution" },
                ]}
                value={trType}
                onChange={onTransactionTypeChange}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Label className="form-label">Date</Label>
        <Row>
          <Col>
            <Flatpickr
              ref={dateFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Flatpickr
              ref={dateFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProvinceTransactionFilter
