import { createSlice } from "@reduxjs/toolkit"

const initialState = { data: [], error: null, loading: false, ip: null }

const monitorDistributionSlice = createSlice({
  name: "monitorDistribution",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setIP(state, action) {
      state.ip = action.payload
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = false
      state.ip = null
    },
  },
})

export const monitorDistributionAction = monitorDistributionSlice.actions

export default monitorDistributionSlice
