import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

const initialState = {
  isLoggedIn: false,
  token: null,
  user: [],
  permissions: [],
  role: null,
  error: null,
  loading: false,
  changePassword: { loading: false, error: null, data: null },
  loginHistories: { loading: false, error: null, data: null },
}

// const setAuthTokenApi = createAsyncThunk("/authGetToken", async token => {
//   const response = await Api.get("/v1/get-user")
//   return response
// })

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loggedIn(state, action) {
      const { user, token, permissions, role } = action.payload
      state.user = user
      state.permissions = permissions
      state.token = token
      state.role = role
      state.isLoggedIn = true
    },
    loggedOut(state) {
      state.user = []
      state.token = null
      state.permissions = []
      state.role = null
      state.isLoggedIn = false
    },
    setError(state, action) {
      state.error = action.payload
    },
    removeError(state) {
      state.error = null
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setToken(state, action) {
      state.token = action.payload
    },
    setChangePassword(state, action) {
      state.changePassword = {
        loading: action.payload.loading,
        error: action.payload.error,
        data: action.payload.data,
      }
    },
    setLoginHistories(state, action) {
      state.loginHistories = {
        loading: action.payload.loading,
        error: action.payload.error,
        data: action.payload.data,
      }
    },

    resetChangePassword(state) {
      state.changePassword = { loading: false, error: null, data: null }
    },

    setUser(state, action) {
      state.user = action.payload
    },
  },
})

export const authAction = authSlice.actions

export default authSlice
