import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  monitorStock: { error: null, loading: false, data: [] },
  monitorStockExport: { error: null, loading: false },
  MonitorIps: { error: null, loading: false, data: [] },
  MonitorItemCategoires: { error: null, loading: false, data: [] },
  MonitorItems: { error: null, loading: false, data: [] },
  MonitorInItems: { data: [], error: null, loading: null },
  MonitorOutItems: { data: [], error: null, loading: null },
  monitorIpsStock: { error: null, loading: false, data: [] },
  monitorIpsStockExport: { error: null, loading: false },
  monitorWarehouses: { error: null, loading: false, data: [] },
  monitorProviders: { error: null, loading: false, data: [] },
  monitorProvinces: { error: null, loading: false, data: [] },
  monitorFacilities: { error: null, loading: false, data: [] },
  monitorProjects: { error: null, loading: false, data: [] },
}

const monitorDashboardSlice = createSlice({
  name: "monitorDashboard",
  initialState,
  reducers: {
    setMonitorStock(state, action) {
      state.monitorStock = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorStockExport(state, action) {
      state.monitorStockExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setMonitorIps(state, action) {
      state.MonitorIps = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorItemCategories(state, action) {
      state.MonitorItemCategoires = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorItems(state, action) {
      state.MonitorItems = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorInItems(state, action) {
      state.MonitorInItems = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorOutItems(state, action) {
      state.MonitorOutItems = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorIpsStock(state, action) {
      state.monitorIpsStock = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorIpsStockExport(state, action) {
      state.monitorIpsStockExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setMonitorWarehouses(state, action) {
      state.monitorWarehouses = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorProviders(state, action) {
      state.monitorProviders = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorProvinces(state, action) {
      state.monitorProvinces = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorFacilities(state, action) {
      state.monitorFacilities = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorProjects(state, action) {
      state.monitorProjects = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    resetMonitorStock(state) {
      state.monitorStock = { error: null, loading: false, data: [] }
    },
    resetMonitorIps(state) {
      state.MonitorIps = { error: null, loading: false, data: [] }
    },
    resetMonitorItemCategories(state) {
      state.MonitorItemCategoires = { error: null, loading: false, data: [] }
    },
    resetMonitorItems(state) {
      state.MonitorItems = { error: null, loading: false, data: [] }
    },
    resetMonitorInItems(state) {
      state.MonitorInItems = { data: [], error: null, loading: null }
    },
    resetMonitorOutItems(state) {
      state.MonitorOutItems = { data: [], error: null, loading: null }
    },
    resetMonitorIpsStock(state) {
      state.monitorIpsStock = { data: [], error: null, loading: null }
    },
    resetMonitorWarehouses(state) {
      state.monitorWarehouses = { data: [], error: null, loading: null }
    },
    resetMonitorProviders(state) {
      state.monitorMonitors = { data: [], error: null, loading: null }
    },
    resetMonitorProvinces(state) {
      state.monitorProvinces = { data: [], error: null, loading: null }
    },
    resetMonitorFacilities(state) {
      state.monitorFacilities = { data: [], error: null, loading: null }
    },
    resetMonitorProjects(state) {
      state.monitorProjects = { data: [], error: null, loading: null }
    },
    resetMonitorStockExport(state) {
      state.monitorStockExport = { error: null, loading: null }
    },
    resetMonitorIPsStockExport(state) {
      state.monitorIpsStockExport = { error: null, loading: false }
    },
  },
})

export const monitorDashboardAction = monitorDashboardSlice.actions

export default monitorDashboardSlice
