import React from "react"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"

const ActionColumns = ({
  enableEdit,
  enableDelete,
  enableDetail,
  enableDocumentLink,
  documentLink,
  editRoute,
  onClickDelete,
  onClickDetail,
  data,
}) => {
  return (
    <div className="d-flex gap-3">
      {enableDetail && (
        <Link
          to="#"
          className="text-info"
          onClick={e => {
            e.preventDefault()
            onClickDetail(data)
          }}
        >
          <i
            className="mdi mdi-format-list-bulleted font-size-18"
            id="detailtooltip"
          />
          <UncontrolledTooltip placement="top" target="detailtooltip">
            Details
          </UncontrolledTooltip>
        </Link>
      )}

      {enableDocumentLink && (
        <a
          href={documentLink ? documentLink : ""}
          target="_blank"
          rel="noreferrer"
          download={true}
          className="text-info"
        >
          <i className="bx bx-link font-size-18" id="detailtooltip" />
          <UncontrolledTooltip placement="top" target="detailtooltip">
            Show Document
          </UncontrolledTooltip>
        </a>
      )}

      {enableEdit && (
        <Link to={editRoute && editRoute} className="text-success">
          <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
          <UncontrolledTooltip placement="top" target="edittooltip">
            Edit
          </UncontrolledTooltip>
        </Link>
      )}
      {enableDelete && (
        <Link
          to="#"
          className="text-danger"
          onClick={e => {
            e.preventDefault()
            onClickDelete(data)
          }}
        >
          <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
          <UncontrolledTooltip placement="top" target="deletetooltip">
            Delete
          </UncontrolledTooltip>
        </Link>
      )}
    </div>
  )
}

export default ActionColumns
