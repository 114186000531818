import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import FacilityListComponent from "components/Facility/list"
import { deleteFacility, getFacilities } from "store/facility/facility-action"
import ItemListComponent from "components/Item/list"
import { deleteItem, exportItems, getItems } from "store/item/item-action"

const ItemList = () => {
  document.title = "Items"

  const dispatch = useDispatch()
  const { data, error, loading, exportStatus } = useSelector(
    state => state.item
  )
  const [deleteModal, setDeleteModal] = useState(false)
  const [item, setItem] = useState(null)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })

  const handleDeleteFunc = data => {
    if (item) {
      dispatch(deleteItem(item.id))
    }
    setDeleteModal(false)
  }

  const onClickDelete = data => {
    setItem(data)
    setDeleteModal(true)
  }

  const onPageClick = link => {
    dispatch(getItems(link.url, pageNumber, according, search))
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getItems(undefined, undefined, undefined, search))
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(getItems(undefined, pageNumber, according, search))
  }, [pageNumber, according])

  const onExportBtnClick = () => {
    dispatch(exportItems(search))
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteFunc}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Breadcrumbs title={"Admin"} breadcrumbItem={"Items"} />
      <ItemListComponent
        data={data}
        error={error}
        loading={loading}
        onClickDelete={onClickDelete}
        handleDeleteFunc={handleDeleteFunc}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        exportStatus={exportStatus}
        onExportBtnClick={onExportBtnClick}
      />
    </React.Fragment>
  )
}

export default ItemList
